import { getLanguageFromPath } from '~/helpers/language'

/**
 * Redirects to the login page if the user is not logged in.
 */
export default defineNuxtRouteMiddleware((to) => {
  const drupalUser = useDrupalUser()

  // Because of a bug in nuxt-language-negotiation, the middleware is called twice.
  // This is a workaround to prevent the middleware from running twice.
  // in the first call, the plugin is not ready yet.
  if (!drupalUser.value.fetched) {
    return
  }

  if (drupalUser.value.loggedIn) {
    return navigateTo({
      name: 'user-overview___' + getLanguageFromPath(to.path),
      params: {
        id: drupalUser.value.uid,
      },
    })
  }
})
